@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flexJustifyBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@mixin flexColumnBetween {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@mixin Scrollbar {
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #777;
    border-radius: 8px;
  }
}

@mixin ZoomScaller {
  overflow: hidden;
  img {
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    img {
      transition: all 0.3s ease-in-out;
      transform: scale(1.012);
      border-radius: 12px;
    }
  }
}

ul {
  margin-bottom: 0 !important;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin respond($breakpoint) {
  // $breakpoint is simply a variable that can have several values

  @if $breakpoint==bigScreen {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 3000px) {
      @content;
    }
  }

  @if $breakpoint==midScreen {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 1600px) {
      @content;
    }
  }
  @if $breakpoint==smScreen {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 1367px) {
      @content;
    }
  }
  @if $breakpoint==smScreen1 {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint==smScreen2 {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint==smScreen3 {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 800px) {
      @content;
    }
  }

  @if $breakpoint==smScreen4 {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 540px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
  @if $breakpoint==smMobile {
    @media only screen and (max-width: 412px) {
      @content;
    }
  }
}
