@import "../common/breakpoint.scss";
.faq-wrapper {
    .faq-head{
        text-align: center;
        h5{
            font-weight: bold;
            @include breakpoint (upto-mobile) {
                font-size: 1.3750em!important;
            }
        }
    }
.answer-wrap {
    h2 {
        font-size: .9375em;
        color: #000000;
        text-transform: capitalize;
        line-height: 1.5;
        letter-spacing: .5px;
        word-spacing: 1px;
        font-weight: 600;
        margin-bottom: .5rem;
        word-break: break-word; 
        @include breakpoint (upto-mobile){
            font-size: .8550em;
        }
    }

    ul {
        li {
            font-size: .8750em;
            text-transform: capitalize;
            font-weight: 500;
            margin-left: 0.5rem;
            list-style-type: "-";
            padding: .5rem 0 .5rem .8rem;
            line-height: 1.3;
            word-break: break-word;
            @include breakpoint (upto-mobile){
                font-size: .8450em;
            }
        }
    }
}
}